import { create } from 'zustand';
import { fetchUsersDetails } from '../api/admin';

const initialState = {
  usersDetails: [],
  error: null,
  isLoading: false,
};

const useAdminStore = create(set => ({
  ...initialState,

  getUsersDetails: async (): Promise<void> => {
    set({ isLoading: true, error: null });
    try {
      const usersDetails = await fetchUsersDetails();
      set({
        usersDetails: usersDetails.data.data,
        isLoading: false,
        error: null,
      });
    } catch (error) {
      set({ isLoading: false, error });
    }
  },
}));

export default useAdminStore;
