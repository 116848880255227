import GenericLayout from '../../common/layout/GenericLayout';
import { Card } from 'primereact/card';
import AdminUsersListPage from './AdminUserList';

const AdminHomePage = () => {
  const cardData = [
    { title: 'No Of Users', subTitle: '2' },
    { title: 'Active Instances', subTitle: '10' },
    { title: 'No Of Users', subTitle: '3' },
    { title: 'Active Instances', subTitle: '2' },
  ];
  return (
    <GenericLayout>
      <div className="flex">
        {/* Left Nav */}
        <nav className="w-64 min-h-screen bg-sky-950 text-white p-4">
          <div className="flex items-center mb-6 border-t-[1px] border-t-white">
            <span className="ml-2 text-xl font-bold"></span>
          </div>

          <ul className="space-y-2">
            <li className="p-2 hover:bg-gray-700 rounded cursor-pointer">
              Home
            </li>
            <li className="p-2 hover:bg-gray-700 rounded cursor-pointer">
              Users
            </li>
            <li className="p-2 hover:bg-gray-700 rounded cursor-pointer">
              Instances
            </li>
            <li className="p-2 hover:bg-gray-700 rounded cursor-pointer">
              Payments
            </li>
            <li className="p-2 hover:bg-gray-700 rounded cursor-pointer">
              Settings
            </li>
          </ul>
        </nav>

        {/* Main Content */}
        <div className="flex-1 p-4">
          <div className="md:px-6 px-4 pt-12 pb-24 flex flex-row gap-4">
            {cardData.map((card, index) => (
              <div key={index} className="flex-1 bg-blue-100 rounded">
                <Card
                  title={card.title}
                  subTitle={card.subTitle}
                  className="md:w-25rem"
                />
              </div>
            ))}
          </div>

          <div className="md:px-6 px-4 pt-12 pb-24 flex lg:flex-col w-full">
            <div className="flex items-center justify-end mb-4">
              {/* <Button label="Primary" text raised className="mr-2"  onClick={handleSendMaintainceMail}/> */}
              {/* Add mr-2 for horizontal spacing */}
              <span className="flex-grow" />
              {/* Add a flexible spacer to push the button to the left */}
            </div>
            <AdminUsersListPage />
          </div>
        </div>
      </div>
    </GenericLayout>
  );
};

export default AdminHomePage;
