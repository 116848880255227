/* eslint-disable */
const awsExports: any = {
  aws_project_region: import.meta.env.VITE_AWS_COGNITO_REGION,
  aws_cognito_region: import.meta.env.VITE_AWS_COGNITO_REGION,
  aws_user_pools_id: import.meta.env.VITE_COGNITO_USER_POOLS_ID,
  aws_user_pools_web_client_id: import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain:
    import.meta.env.VITE_COGNITO_DOMAIN,
    scope: [
      'aws.cognito.signin.user.admin',
      'email',
      'openid',
      'phone',
      'profile',
    ],
    redirectSignIn:
      'https://auth.dev.node-ready.com/login,http://localhost:8080/login,https://master.dd4dlkdwddjcu.amplifyapp.com/login,https://node-ready.com/login',
    redirectSignOut:
      'https://auth.dev.node-ready.com/logout,http://localhost:8080/logout,https://master.dd4dlkdwddjcu.amplifyapp.com/logout,https://node-ready.com/logout',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL', 'GIVEN_NAME', 'FAMILY_NAME'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 12,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsExports;
