/* eslint-disable no-magic-numbers */
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useAdminStore from '../../store/AdminStore';
import { Button } from 'primereact/button';
import { sendMaintenanceNotification } from '../../utils/mails/maintaince';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import { sendEmail } from '../../api/email';

interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
}

const AdminUsersListPage: React.FC = () => {
  const [formattedUsers, setFormattedUsers] = useState<User[]>([]);
  const [visible, setVisible] = useState(false);
  const [maintenanceDate, setMaintenanceDate] = useState<Nullable<Date>>(null);
  const [startTime, setStartTime] = useState<Nullable<Date>>(null);
  const [endTime, setEndTime] = useState<Nullable<Date>>(null);
  const calendarRef = useRef(null);
  const handleSendMaintainceMail = async () => {
    setVisible(true); // Show the popup
  };

  const handlePopupSubmit = async () => {
    try {
      if (startTime && endTime && maintenanceDate) {
        const startTimeString = startTime.toTimeString().slice(0, 8); // Extract "HH:MM:SS"
        const endTimeString = endTime.toTimeString().slice(0, 8);
        const maintenanceDateString = maintenanceDate.toDateString();
        const maintainceMailUserDetails = formattedUsers.filter(
          ({ firstName, lastName, email }) => {
            if (email !== null || email !== undefined)
              return {
                firstName,
                lastName,
                email,
              };
          }
        );
        sendMaintenanceNotification(
          maintainceMailUserDetails,
          maintenanceDateString,
          startTimeString,
          endTimeString
        ).then(sendMaintainceMailData => {
          sendMaintainceMailData.forEach(eachUser => sendEmail(eachUser));
          setMaintenanceDate(null);
          setStartTime(null);
          setEndTime(null);
        });
        setVisible(false); // Hide the popup
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error sending maintenance notification:', error);
      setMaintenanceDate(null);
      setStartTime(null);
      setEndTime(null);
    }
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <Button
        icon="pi pi-megaphone"
        rounded
        raised
        onClick={handleSendMaintainceMail}
      />
    </div>
  );

  const headers = [
    { field: 'firstName', header: 'First Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'email', header: 'Email' },
    { field: 'createdAt', header: 'Created On' },
  ];

  const { getUsersDetails, usersDetails } = useAdminStore() as {
    getUsersDetails: () => Promise<void>;
    usersDetails: User[];
  };

  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return dateObj.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUsersDetails();
      const formattedData = usersDetails.map(user => ({
        ...user,
        createdAt: formatDate(user.createdAt),
      }));
      setFormattedUsers(formattedData);
    };

    fetchData();
  }, [getUsersDetails, formattedUsers, usersDetails]);

  const renderCalendarField = (
    id: string,
    label: string,
    value: Nullable<Date>,
    onChange: (_value: Nullable<Date>) => void,
    timeOnly?: boolean
  ) => (
    // <div className="card flex justify-content-center">
    <div className="flex-auto">
      <label htmlFor={id} className="block text-sm font-medium mb-2">
        {label}
      </label>
      <Calendar
        id={id}
        value={value}
        onChange={e => onChange(e.value)}
        timeOnly={timeOnly}
        showIcon
        ref={calendarRef}
        showButtonBar
      />
    </div>
    // </div>
  );

  return (
    <div className="card">
      {/* {formattedUsers.length > 0 && ( */}
      <DataTable
        value={formattedUsers}
        showGridlines
        paginator
        rows={10}
        header={header}
        lazy={true}
      >
        {headers.map(header => (
          <Column
            key={header.field}
            field={header.field}
            header={header.header}
            headerStyle={{ backgroundColor: 'lightgray', color: 'black' }}
          />
        ))}
      </DataTable>
      {/* )} */}

      <Dialog
        header="Schedule Maintenance"
        style={{ width: '30vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <div className="card grid gap-4 py-4 justify-content-center">
          {renderCalendarField(
            'maintenanceDate',
            'Maintenance Date:',
            maintenanceDate,
            setMaintenanceDate
          )}
          {renderCalendarField(
            'startTime',
            'Start Time:',
            startTime,
            setStartTime,
            true
          )}
          {renderCalendarField(
            'endTime',
            'End Time:',
            endTime,
            setEndTime,
            true
          )}

          <div className="flex justify-end mt-6">
            <Button
              label="Submit"
              onClick={handlePopupSubmit}
              className="w-full sm:w-auto"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AdminUsersListPage;
