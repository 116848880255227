/* eslint-disable max-len */

export const sendMaintenanceNotification = async (
  recipients: { firstName: string; lastName: string; email: string }[],
  date: string,
  startTime: string,
  endTime: string
) => {
  try {
    const mails = [];
    for (const recipient of recipients) {
      const msg = {
        data: {
          content: [
            {
              type: 'text/html',
              value:
                '\n<!DOCTYPE html>\n<html>\n<head>\n  <style>\n    body {\n      font-family: sans-serif;\n      background-color: #f0f0f0;\n      margin: 0;\n      padding: 0;\n    }\n\n    .container {\n      max-width: 600px;\n      margin: 0 auto;\n      padding: 20px;\n      background-color: #fff;\n      border-radius: 5px;\n      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    }\n\n    h1 {\n      color: #333;\n      font-size: 24px;\n      margin-bottom: 20px;\n    }\n\n    p {\n      color: #666;\n      margin-bottom: 15px;\n    }\n\n    ul {\n      list-style: none;\n      padding: 0;\n    }\n\n    li {\n      margin-bottom: 10px;\n    }\n\n    .button {\n      display: inline-block;\n      padding: 10px 20px;\n      background-color: #007bff;\n      color: #fff;\n      text-decoration: none;\n      border-radius: 3px;\n    }\n  </style>\n</head>\n<body>\n  <div class="container">\n    <h1>Application Maintenance Notification</h1>\n    <p>Dear User,</p>\n    <p>Please be informed that scheduled application maintenance will be performed on <strong>' +
                date +
                '</strong> from <strong>' +
                startTime +
                '</strong> to <strong>' +
                endTime +
                '</strong>.</p>\n    <p>During this time, you may experience temporary interruptions in service, such as:</p>\n    <ul>\n      <li>Limited or no access to the application.</li>\n      <li>Slow response times.</li>\n      <li>Service unavailability.</li>\n    </ul>\n    <p>We apologize for any inconvenience this may cause and appreciate your understanding.</p>\n    <p>Thank you,</p>\n    <p>The Node-Ready Team</p>\n  </div>\n</body>\n</html>\n',
            },
          ],
          from: {
            email: 'no-reply@node-ready.com',
            name: 'Node-Ready',
          },
          mailSettings: {
            bypassListManagement: {
              enable: false,
            },
            footer: {
              enable: false,
            },
            sandboxMode: {
              enable: false,
            },
          },
          personalizations: [
            {
              to: [
                {
                  email: recipient.email,
                  name: recipient.firstName + recipient.lastName,
                },
              ],
            },
          ],
          subject: 'Application Maintenance Notification',
          trackingSettings: {
            clickTracking: {
              enable: true,
              enableText: false,
            },
            openTracking: {
              enable: true,
              substitutionTag: '%open-track%',
            },
            subscriptionTracking: {
              enable: false,
            },
          },
        },
      };
      mails.push(msg);
    }
    return mails;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error sending maintenance notifications:', error);
    return [];
  }
};

// Example usage:
// const recipients = [
//   { firstName: 'John', lastName: 'Doe', email: 'john.doe@example.com' },
//   { firstName: 'Jane', lastName: 'Smith', email: 'jane.smith@example.com' },
// ];
