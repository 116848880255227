import { createApiClient } from '../../middleware/Middleware';

// Creating API client instance
const adminApi = createApiClient({
  baseURL: import.meta.env.VITE_ADMIN_API,
  additionalHeaders: { 'x-api-key': import.meta.env.VITE_ADMIN_KEY },
});

export const fetchUsersDetails = async () => {
  return adminApi
    .get(`/v1/users`)
    .then(async response => {
      return {
        data: response,
        message: 'Retrieved users details successfully!',
      };
    })
    .catch((error: Error) => {
      throw new Error(`Failed to retrieve users details, ${error.message}`);
    });
};
