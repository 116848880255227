import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LoginFormInputType } from '../../types/Forms.type';
import { UserDetails } from '../../types/UserDetails.type';
import useAuthStore from '../../store/AuthenticationStore';
import FooterComponent from '../../common/layout/FooterComponent';
import { errorMessages, successMessages } from '../../constants/messages';
import { navigators } from '../../constants/navigators';
import {
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  signIn,
  signOut,
} from '@aws-amplify/auth';
import { useEffect, useState } from 'react';
import RotatingLinesLoader from '../../common/components/spinners/RotatingLinesLoader';
import LoginBannerComponent from '../../common/layout/LoginBannerComponent';
import { maskValue, scrollToTop } from '../../utils/reUsableFunctions';

const AdminLoginPage = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputType>();

  const { setUserDetails } = useAuthStore() as {
    setUserDetails: (
      _newUserDetails: UserDetails | FetchUserAttributesOutput
    ) => void;
    authToken: string | null;
    logOut: () => void;
  };

  const navigate = useNavigate();

  useEffect(() => {
    signOut();
    localStorage.clear();
    scrollToTop();
  }, []);

  const onLoginSubmit: SubmitHandler<LoginFormInputType> = async (
    userData: LoginFormInputType
  ) => {
    setLoading(true);

    try {
      const signInResponse = await signIn({
        username: userData.email,
        password: userData.password,
      });

      const { signInStep } = signInResponse.nextStep;

      if (signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        navigate(
          `${navigators.NAVIGATE_TO_RESET_PASSWORD}?isChangePassword=true`
        );
        toast.success(successMessages.CHANGE_NEW_PASSWORD);
        return;
      }

      const sessionResponse = await fetchAuthSession();
      const accessToken = sessionResponse?.tokens?.accessToken;

      const clientId = accessToken?.payload?.client_id
        ? String(accessToken.payload.client_id)
        : '';

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const userAttributes: any = await fetchUserAttributes();

      if (!userAttributes) {
        throw new Error('Failed to fetch user attributes.');
      }

      const userDetailsWithClientId = {
        ...userAttributes,
        clientId,
        accessId: accessToken?.payload.username,
        password: maskValue(userData.password),
      };
      setUserDetails(userDetailsWithClientId);

      toast.success(successMessages.SUCCESS_LOGIN);

      const navigateTo =
        userDetailsWithClientId['custom:setup'] === undefined ||
        userDetailsWithClientId['custom:setup'] === 'false'
          ? navigators.NAVIGATE_TO_ACCOUNT_SETUP
          : navigators.NAVIGATE_TO_HOME;

      navigate(navigateTo);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : errorMessages.UNKNOWN_ERROR;

      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="h-screen w-full flex overflow-hidden">
        <form
          className="lg:w-[45%] w-full px-6 md:px-24 py-12 bg-primary-body h-full"
          onSubmit={handleSubmit(onLoginSubmit)}
        >
          <div className="py-5">
            <h1 className="text-3xl font-extrabold text-gray-900 font-roboto-slab">
              Sign in to your account
            </h1>
          </div>
          <div className="space-y-6">
            <div>
              <p className="text-sm text-gray-700 font-medium">Email address</p>
              <input
                className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                  ${errors.email ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
                id="email"
                type="email"
                {...register('email', { required: 'Email is required' })}
              />
              {errors.email && (
                <p className="text-sm text-red-500 mt-1">
                  {errors.email.message}
                </p>
              )}
            </div>

            <div>
              <p className="text-sm text-gray-700 font-medium">Password</p>
              <input
                type="password"
                className={`bg-white px-4 py-2 shadow-sm border-[1px] w-full rounded-md outline-none
                  ${errors.password ? 'border-red-500 focus:border-red-400' : 'border-gray-300 focus:border-primary'}`}
                id="password"
                {...register('password', { required: 'Password is required' })}
              />
              {errors.password && (
                <p className="text-sm text-red-500 mt-1">
                  {errors.password.message}
                </p>
              )}
            </div>
            <button
              type="submit"
              className={`bg-primary flex items-center justify-center gap-4 relative hover:bg-orange-500 
              hover:shadow-2xl px-4 py-3 w-full text-white font-medium rounded-3xl shadow-sm ${loading && 'opacity-70'}`}
            >
              {loading && <RotatingLinesLoader color="white" width="24" />} Sign
              in
            </button>
          </div>
        </form>
        <div className=" w-[55%] relative lg:flex hidden">
          <LoginBannerComponent />
        </div>
      </section>
      <section>
        <FooterComponent />
      </section>
    </>
  );
};

export default AdminLoginPage;
