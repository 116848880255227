import { Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/auth/LoginPage';
import HomePage from '../pages/HomePage';
import { AuthenticatedGuard } from './AuthenticatedGuard';
import EmailConfirmationPage from '../pages/auth/EmailConfirmationPage';
import SignUpPage from '../pages/auth/SignUpPage';
import ForgotPasswordPage from '../pages/auth/ForgotPasswordPage';
import ResetPasswordPage from '../pages/auth/ResetPasswordPage';
import AccountSetUpPage from '../pages/AccountSetUpPage';
import PaymentCallbackPage from '../pages/PaymentCallbackPage';
import InstanceProvisioningJobsPage from '../pages/InstanceProvisioningJobsPage';
import ServiceProviderLoginPage from '../pages/auth/ServiceProviderLoginPage';
import NotFoundPage from '../pages/NotFoundPage';
import AdminLoginPage from '../pages/admin/AdminLoginPage';
import AdminHomePage from '../pages/admin/AdminHomePage';

const Routers = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/admin" element={<AdminLoginPage />} />
      <Route path="/admin/home" element={<AdminHomePage />} />
      <Route
        path="/service-provider/login"
        element={<ServiceProviderLoginPage />}
      />
      <Route path="/email-confirmation" element={<EmailConfirmationPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path="/account-setup"
        element={
          <AuthenticatedGuard>
            <AccountSetUpPage />
          </AuthenticatedGuard>
        }
      />
      <Route
        path="/"
        element={
          <AuthenticatedGuard>
            <HomePage />
          </AuthenticatedGuard>
        }
      />
      <Route
        path="/instance-provisioning-jobs/callback"
        element={
          <AuthenticatedGuard>
            <PaymentCallbackPage />
          </AuthenticatedGuard>
        }
      />
      <Route
        path="/subscription/callback/"
        element={
          <AuthenticatedGuard>
            <PaymentCallbackPage />
          </AuthenticatedGuard>
        }
      />
      <Route
        path="/instance-provisioning-jobs/:id"
        element={
          <AuthenticatedGuard>
            <InstanceProvisioningJobsPage />
          </AuthenticatedGuard>
        }
      />
    </Routes>
  );
};

export default Routers;
