/* eslint-disable camelcase */
import { createApiClient } from '../../middleware/Middleware';

// Creating API client instance
const paymentApi = createApiClient({
  baseURL: import.meta.env.VITE_PAYMENT_API_URL,
  additionalHeaders: { apiKey: import.meta.env.VITE_PAYMENT_API_KEY },
});

export const fetchPaymentDetails = async (customerId: string) => {
  return paymentApi
    .get(`/customer/getCustomerPaymentMethods?id=${customerId}`)
    .then(async response => {
      return {
        data: response,
        message: 'Retrieved payment details successfully!',
      };
    })
    .catch((error: Error) => {
      throw new Error(`Failed to retrieve payment details, ${error.message}`);
    });
};

export const fetchCustomerPortalSession = async (
  customerId: string,
  reDirectUrl: string
) => {
  return paymentApi
    .post(`/customer-portal/createCustomerPortalSession`, {
      customer: customerId,
      locale: 'en',
      return_url: reDirectUrl,
    })
    .then(async response => {
      return {
        data: response,
        message: 'Retrieved Customer Portal successfully!',
      };
    })
    .catch((error: Error) => {
      throw new Error(`Failed to retrieve Customer Portal, ${error.message}`);
    });
};
