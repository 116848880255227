import { createApiClient } from '../../middleware/Middleware';

// Creating API client instance
const sendMailApi = createApiClient({
  baseURL: import.meta.env.VITE_EMAIL_API,
  additionalHeaders: { apiKey: import.meta.env.VITE_EMAIL_API_KEY },
});

export const sendEmail = async (data: unknown) => {
  return sendMailApi
    .post(`sendMail`, data)
    .then(async response => {
      return {
        data: response,
        message: 'Mail sent successfully!',
      };
    })
    .catch((error: Error) => {
      throw new Error(`Failed to send mail, ${error.message}`);
    });
};
